import GraphCMS from "services/cms/GraphQL.service";
import GetForm from "services/cms/queries/GetForm";
import FormService from 'services/cms/Form.service';

const cmsGraphQl = new GraphCMS();
const formService = new FormService()


// Gets All Blog Category Entries
async function GetFreeForm(handle) {
	const formQuery = GetForm(handle);

	return await cmsGraphQl.post(formQuery).then((res) => {
        return res?.data?.freeform?.form
    })
}

 

async function GetFormData(handle){
    return await formService.getFormData(handle).then((res) => {
        return res?.data;
    })
}


async function PostForm(data){
    return await formService.postForm(data).then((res) => res?.data)
}


export {
    GetFormData,
    GetFreeForm,
    PostForm
}