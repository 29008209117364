'use client'

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { GetFormData, PostForm } from 'contexts/FormContext';
import clsx from 'clsx';
import Style from './RenderForm.module.scss';
import Col from 'styles/globals/gridUtils.module.scss';
import utils from 'styles/globals/utils.module.scss';
import ErrorBoundary from 'services/ErrorBoundary';
import dynamic from 'next/dynamic';

const Textarea = dynamic(() => import('components/forms/Textarea'), { ssr: false });
const Button = dynamic(() => import('components/forms/Button'), { ssr: false });
const Input = dynamic(() => import('components/forms/Input'), { ssr: false });
const StateSelect = dynamic(() => import('components/forms/StateSelect'), { ssr: false });
const Select = dynamic(() => import('components/forms/Select'), { ssr: false });
const Radio = dynamic(() => import('components/forms/Radio'), { ssr: false });
const Checkbox = dynamic(() => import('components/forms/Checkbox'), { ssr: false });
const PhoneInput = dynamic(() => import('components/forms/PhoneInput'), { ssr: false });
const Loader = dynamic(() => import('components/feedback/Loader'), { ssr: false });
const FileUpload = dynamic(() => import('components/forms/FileUpload'), { ssr: false });
const CheckboxGroup = dynamic(() => import('components/forms/CheckboxGroup'), { ssr: false });

const RenderForm = ({
    formControl,
    noPad = false,
    fullWidthForm = false,
    formClass,
    legendClass,
    hideLegend = false,
    onSuccess = () => null,
    onFailure = () => null,
    controlData,
    tradeStatus,
    customer_group_id,
}) => {

    const methods = useForm();
    const { handleSubmit, control, setValue, getValues, formState: { errors } } = methods;

    const [loading, setLoadingState] = useState({
        active: false,
        isDone: null,
        hasError: null,
    });

    const [sent, setSent] = useState(false);
    const [renderFormData, setRenderFormData] = useState(false);
    const [files, setFiles] = useState([]);

    const postForm = async (formData) => {
        setLoadingState((loading) => ({ ...loading, active: true }));

        try {
            const responseData = await GetFormData(formControl.handle);
            setRenderFormData(responseData);

            formData.formHash = responseData.hash;
            formData.action = responseData.action;
            formData.freeform_payload = responseData.freeform_payload;

            if (responseData.csrf) {
                formData[responseData.csrf.name] = responseData.csrf.token;
            }

            if (responseData.honeypot) {
                formData[responseData.honeypot.name] = responseData.honeypot.value;
            }

            // Process checkbox data to include only selected values
            formControl.fields.forEach((fieldData) => {
                if (fieldData.type === 'checkboxes') {
                    const selectedValues = getValues(fieldData.handle, fieldData.options) || [];

                    // Store selected values as a comma-separated string or a list
                    formData[fieldData.handle, fieldData.options] = selectedValues.length > 0 ? selectedValues.join(', ') : '';
                }
            });

            // Upload files to S3 and get URLs (if any)
            if (files.length > 0) {
                const fileContents = await Promise.all(
                    files.map(async (file) => {
                        const content = await file.arrayBuffer();
                        return {
                            name: file.name,
                            type: file.type,
                            content: Buffer.from(content).toString('base64'),
                        };
                    })
                );

                const response = await fetch('/api/cms/forms/upload', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ files: fileContents }),
                });

                if (!response.ok) {
                    throw new Error('Error uploading files');
                }

                const data = await response.json();
                const uploadedFiles = data.locations;

                // Format URLs as an unordered list
                const formattedUrls = `<ul>${uploadedFiles.map(url => `<li><a href="${url}">${url}</a></li>`).join('')}</ul>`;

                // Identify the Textarea field and append the URLs to its content
                formControl.fields.forEach((fieldData) => {
                    if (fieldData.type === 'textarea') {
                        const textareaContent = getValues(fieldData.handle) || '';
                        const updatedTextareaContent = `${textareaContent}\n\n${formattedUrls}`;
                        setValue(fieldData.handle, updatedTextareaContent);
                        formData[fieldData.handle] = updatedTextareaContent;
                    }
                });

                // Add file URLs to formData
                formData.fileUrls = uploadedFiles;
            }

            const postResponse = await PostForm(formData);
            setLoadingState((loading) => ({ ...loading, isDone: true }));

            const reactToast = await import('react-toastify');
            reactToast.toast(responseData.successMessage);

            setSent(true);
            onSuccess();

            setTimeout(() => {
                setLoadingState((loading) => ({ ...loading, active: false, isDone: null }));
            }, 3000);
        } catch (err) {
            console.error('Error submitting the form:', err);

            setLoadingState((loading) => ({ ...loading, hasError: true }));

            const reactToast = await import('react-toastify');
            reactToast.toast.error('There was an error submitting the form.');

            onFailure();

            setTimeout(() => {
                setLoadingState((loading) => ({ ...loading, active: false, hasError: null }));
            }, 3000);
        }
    };

    if (sent) {
        return (
            <p className={clsx(utils.mt_2, utils.text_center)}>
                {renderFormData.successMessage}
            </p>
        );
    }

    if (customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    }

    return (
        <ErrorBoundary>
            <FormProvider {...methods}>
                <form
                    method="post"
                    name={formControl.name}
                    className={clsx(Style.block, formClass, noPad && utils.p_0)}
                    id={formControl.uid}
                    onSubmit={handleSubmit(postForm)}
                >
                    <fieldset className={Style.wrapper}>
                        {!hideLegend && (
                            <legend className={clsx(utils.fs_2, legendClass)}>
                                {formControl.name}
                            </legend>
                        )}

                        {formControl?.description && (
                            <p className={Style.description}>{formControl.description}</p>
                        )}

                        {formControl?.fields?.map((fieldData, index) => {
                            // HTML/RichText Field
                            let isContentField = fieldData.type === 'html' || fieldData.type === 'rich-text';

                            if (isContentField) {
                                return (
                                    <div
                                        key={fieldData.id || index}
                                        className={clsx(Col.md_10, Style.controlGroup)}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{ __html: fieldData.value }}
                                        />
                                    </div>
                                );
                            }

                            if (fieldData.type === 'file-dnd') {
                                return (
                                    <FileUpload
                                        key={fieldData.id}
                                        acceptedText="Supported files: PDF, DOCX, JPG (Max size: 5MB)"
                                        onSetFiles={setFiles}
                                        label={fieldData.label}
                                    />
                                );
                            }

                            if (fieldData.type === 'radios' || fieldData.type === 'radio') {
                                // Radio Field
                                return (
                                    <div
                                        key={fieldData.hash}
                                        className={clsx(Col.md_5, Style.controlGroup, fullWidthForm && Col.md_10)}
                                    >
                                        {fieldData.label && (
                                            <label className={clsx(Style.groupLabel, Style.label)}>
                                                {fieldData.label}
                                            </label>
                                        )}

                                        {fieldData.options?.map((option, optionIndex) => (
                                            <Controller
                                                key={`${fieldData.id}-${optionIndex}`}
                                                name={fieldData.handle}
                                                control={control}
                                                rules={{ required: fieldData.required }}
                                                defaultValue=""
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <Radio
                                                            {...field}
                                                            label={option.label}
                                                            value={option.value}
                                                            isSelected={field.value === option.value}
                                                            onChange={(e) => field.onChange(e)}
                                                        />
                                                        {fieldState.error && (
                                                            <span className={Style.error}>
                                                                {fieldState.error.message || '*This field is required'}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        ))}
                                    </div>
                                );
                            }

                            if (fieldData.type === 'checkboxes') {
                                // Checkbox Group Field
                                return (
                                    <CheckboxGroup
                                        key={index}
                                        label={fieldData.label}
                                        name={fieldData.handle}
                                        options={fieldData.options}
                                        required={fieldData.required}
                                    />
                                );
                            }

                            let Component;
                            let extraProps = {};

                            switch (fieldData.type) {
                                case 'text':
                                case 'email':
                                    Component = Input;
                                    extraProps = { type: fieldData.type };
                                    break;
                                case 'phone':
                                    Component = PhoneInput;
                                    break;
                                case 'checkbox':
                                    Component = Checkbox;
                                    break;
                                case 'dropdown':
                                    Component = fieldData.handle === 'state' ? StateSelect : Select;
                                    break;
                                case 'textarea':
                                    Component = Textarea;
                                    extraProps = { name: fieldData.handle };
                                    break;
                            }

                            // Other Field types
                            if (Component) {
                                return (
                                    <div
                                        key={fieldData.id}
                                        className={clsx(fieldData.type === 'textarea' ? Col.md_10 : Col.md_5, Style.controlGroup, fullWidthForm && Col.md_10)}
                                    >
                                        <Controller
                                            name={fieldData.handle}
                                            control={control}
                                            rules={{ required: fieldData.required }}
                                            defaultValue=""
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <Component
                                                        {...field}
                                                        {...extraProps}
                                                        helpText={fieldData.instructions}
                                                        label={fieldData.label}
                                                        required={fieldData.required}
                                                        placeholder={fieldData.placeholder}
                                                        id={fieldData.id}
                                                    />
                                                    {fieldState.error && (
                                                        <span className={Style.error}>
                                                            {fieldState.error.message || '*This field is required'}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                );
                            }
                        })}

                        <div className={clsx(Style.controlGroup, Style.row)}>
                            {formControl.pages?.[0]?.buttons?.back && (
                                <span className={Style.buttonPad}>
                                    <Button type="button" onClick={() => window.history.back()}>
                                        {formControl.pages[0].buttons.backLabel || 'Back'}
                                    </Button>
                                </span>
                            )}
                            <span className={Style.buttonPad}>
                                <Button
                                    type="submit"
                                    disabled={loading.active}
                                    onClick={handleSubmit(postForm)}
                                >
                                    {loading.active ? <Loader /> : formControl?.pages?.[0]?.buttons.submitLabel}
                                </Button>
                            </span>
                        </div>
                    </fieldset>
                </form>
            </FormProvider>
        </ErrorBoundary>
    );
}

RenderForm.propTypes = {
    formControl: PropTypes.object,
};

export default RenderForm;
