
/**
 * Gets a Freeform Form from the CMS
 * @param {String} handle - the forms handle
 */

export default function GetForm(handle){
    return `query GetForm {
                freeform {
                    form(handle: "${handle}") {
                        id
                        name
                        uid
                        hash
                        handle
                        returnUrl
                        description
                        pages {
                            buttons {
                                layout
                                submitLabel
                                back
                                backLabel
                                save
                                saveLabel
                            }
                        }
                        csrfToken {
                            value
                            name
                        }
                        honeypot {
                            name
                            value
                        }
                        fields {
                            handle
                            label
                            instructions
                            type
                            required
                            
                            ... on FreeformField_Radios {
                                id
                                label
                                type
                                value
                                options {
                                    value
                                    label
                                }
                            }

                            ... on FreeformField_Textarea {
                                rows
                            }

                            ... on FreeformField_FileDnd {
                                id
                                values
                                maxFileSizeKB
                                fileKinds
                                fileCount
                                handle
                            }

                            ... on FreeformField_Checkbox {
                                checked
                            }

                            ... on FreeformField_Checkboxes {
                                values
                                label
                                options {
                                    label
                                    value
                                }
                            }

                            ... on FreeformField_RichText {
                                value
                            }

                            ... on FreeformField_Html {
                                id
                                label
                                type
                                handle
                                value
                            }
                        }
                    }
                }
            }`
}