/** -----------------------------------------------------------------------
 * CMS Form Service
 * Fetches and Submits form data to & from the AT CMS
 * 
 * ------------------------------------------------------------------------ */

 import axios from "axios";




export default class FormService{
    constructor(){}


    /**
     * Get CMS Form Data
     * @param {string} handel - freeform form handel 
     * @returns cms security data to post a cms form
     */

    async getFormData(handel){
        return await axios({
                url: process.env.NEXT_PUBLIC_cms_url + `/formJson`,
                method: "GET",
                withCredentials: true,
                params: {
                    handle: handel
                },
                withCredentials: true
            })
            .catch(err => console.log(err))
    }



    /**
     * Post a Form to the CMS
     * @param {Object} data - serialized form data 
     * @returns 
     */

    async postForm(data){

        return await axios({
                method: "POST",
                url: process.env.NEXT_PUBLIC_cms_url + '/' + data.action, 
                data: data,
                headers: { 
                    'X-Requested-With': 'XMLHttpRequest' 
                },
                withCredentials: true
            })
            .catch(err => {
                console.log(err);
            })
    }



}