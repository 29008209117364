"use client"

import { useState, useEffect } from 'react';


export default function useCallTracking() {
    const [ callRailPh, setCallRailPh ] = useState("");

    useEffect(() => {
        if ( typeof window !== 'undefined' ) {
            getCallTracking();
        }

    }, []);


    const getCallTracking = () => {
            const phoneNumber = process.env.phone;

            try {
                if ("CallTrk" in window) {
                    let ph_no = phoneNumber.replace(/[^\d]/g, ''); //remove non numeric chars
                    ph_no = `${ph_no.substring(ph_no.length - 10, 3)}${ph_no.substring(ph_no.length - 8, 7)}${ph_no.substring(ph_no.length - 4)}`; //get 10 digit number
                
                    CallTrk.getSwapNumbers([ph_no], (result) => {
                        if (result[ph_no]) {
                            ph_no = result[ph_no].replace(/(\d{3})(\d{3})(\d{4})/, "1 ($1) $2-$3"); //format the result
                            setCallRailPh(ph_no);

                        } else {
                           setCallRailPh(phoneNumber);
                        }
                    });

                } else {
                    setCallRailPh(phoneNumber);
                    console.warn("no CallTrk to track");
                }
            }
            catch(err) {
                console.warn(err.message);
            }
    }

    return callRailPh;
}